import "./LoadingComponent.css";
import { Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { LoadingTextStyle } from "../../enviroment/enviroment";

const spinIcon = <LoadingOutlined style={{ fontSize: 120 }} spin />;

export const LoadingComponent = ({ loadingText }) => {
  return (
    <div className="loadingText">
      <Spin
        className="loadingText"
        style={LoadingTextStyle}
        tip={loadingText}
        indicator={spinIcon}
      />
    </div>
  );
};
