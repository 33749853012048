import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Storage } from "aws-amplify";

import "./ShowImageComponent.css";

export interface ShowImageProps {
  item: any;
}

export function ShowImageComponent({ item }: ShowImageProps) {
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    item && console.log(item);
    item && getImages(item.pictures.items);
  }, [item]);

  const getImages = async (pictures: any) => {
    let images: any = [];

    pictures.forEach((image, index) => {
      if (!image.url) {
        Storage.get(image.file.key).then(async (res: any) => {
          images.push({ ...image, url: res });
          if (pictures.length === index + 1) {
            setImages(images);
            item.pictures.items = images;
          }
        });
      } else {
        images.push({ ...image });
        if (pictures.length === index + 1) {
          setImages(images);
          item.pictures.items = images;
        }
      }
    });
  };

  return (
    <>
      {images.length > 0 ? (
        <Carousel style={{ width: "100%" }} indicators={images.length > 1} controls={images.length > 1}>
          {images.map((image: any, index) => {
            return (
              <Carousel.Item key={index} interval={10000}>
                <img
                  key={index}
                  style={{ width: "100%" }}
                  src={image?.url}
                  alt={``}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        <img
          style={{ width: "100%" }}
          src={`${window.location.origin}/images/no-image.jpg`}
          alt={``}
        />
      )}
    </>
  );
}
