import { Col, Row, InputNumber } from "antd";
import { Product } from "../../models";
import { ProductCard } from "../ProductCardComponentView/ProductCardComponent";
import useShoppingCart from "../_Hooks/useShoppingCart";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

export interface CartItem {
  product: Product;
  quantity: number;
}

interface CartItemProps {
  cartItem: CartItem;
}

const iconCartStyle = {
  fontSize: 30,
};

export function CartItemComponent({ cartItem }: CartItemProps) {
  const CartItemOptions = () => {
    const {
      removeProductFromCart,
      addProductToCart,
      setCustomProductQuantity,
    } = useShoppingCart();

    return (
      <Row>
        <Col span={6}>
          <div
            onClick={() => removeProductFromCart(cartItem.product)}
            style={{ textAlign: "center", display: "grid", margin: 5 }}
          >
            {cartItem.quantity === 1 ? (
              <DeleteOutlined style={iconCartStyle} />
            ) : (
              <MinusCircleOutlined style={iconCartStyle} />
            )}
          </div>
        </Col>
        <Col span={12}>
          <div style={{ textAlign: "center", display: "grid", margin: 2 }}>
            Cantidad
          </div>
          <InputNumber
            style={{
              marginLeft: "15%",
            }}
            min={1}
            step={1}
            value={cartItem.quantity}
            onPressEnter={({ target }: any) => {
              let value : number =  Number.parseInt(target.value);
              setCustomProductQuantity(cartItem.product, value);
            }}
            onBlur={({ target }: any) => {
              let value : number =  Number.parseInt(target.value);
              if(value > 0){
                setCustomProductQuantity(cartItem.product, value);
              }
            }}
          />
        </Col>
        <Col span={6}>
          <div
            onClick={() => addProductToCart(cartItem.product)}
            style={{ textAlign: "center", display: "grid", margin: 5 }}
          >
            {" "}
            <PlusCircleOutlined style={iconCartStyle} />{" "}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <ProductCard product={cartItem.product} showOptions={false} />
      <CartItemOptions></CartItemOptions>
    </div>
  );
}
