import { useState } from "react";
import {
  Card,
  Popconfirm,
  notification,
  Modal,
  Collapse,
  Popover,
  Rate,
  Row,
  Col,
  Badge,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import {
  EditOutlined,
  DeleteOutlined,
  SmileOutlined,
  UnorderedListOutlined,
  DollarCircleOutlined,
  EyeInvisibleOutlined,
  SettingOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./ProductCardComponent.css";
import { Product } from "../../models";
import { deleteProduct } from "../../graphql/mutations";
import useUser from "../_Hooks/useUser";
import useShoppingCart from "../_Hooks/useShoppingCart";
import { onDeleteItem } from "../UploadImagesComponent/UploadImageComponent";
import { ShowImageComponent } from "../ShowImageComponentView/ShowImageComponent";
import { ManageProductComponent } from "../ManageProductComponentView/ManageProductComponent";
import useProduct from "../_Hooks/useProduct";
import { CardHeaderStyle, CardStyle, PrimaryButtonStyle, PrimaryButtonTextStyle } from "../../enviroment/enviroment";

const { Panel } = Collapse;

export interface ProductCardProps {
  product: Product;
  showOptions: boolean;
}

export function ProductCard({
  product,
  showOptions,
}: ProductCardProps) {

  const user = useUser();
  const { getProductList } = useProduct();
  const [previewVisible, setIsPreviewVisible] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const AddToCartButton = () => {
    const { addProductToCart, isAddingItem , cartList} = useShoppingCart();
    const isInCart = !!cartList.find((item) => item.product.id === product.id);
    const currentCartItem = cartList.find(
      (item) => item.product.id === product.id
    );
    return (
      <>
        {isAddingItem.id === product.id ? (
          <div className="AddCartModalLoading">
            <LoadingOutlined style={{ fontSize: 32 }} />
          </div>
        ) : (
          <>
            <button
              style={PrimaryButtonStyle}
              className="ButtonPrimaryColor"
              onClick={() => {
                addProductToCart(product);
              }}
            >
              <Badge
                className="ButtonBadge"
                count={isInCart ? currentCartItem.quantity : 0}
              >
                <div className="ButtonPrimaryTextColor" style={PrimaryButtonTextStyle}> Agregar a la bolsa</div>
              </Badge>
            </button>
          </>
        )}
      </>
    );
  };

  const onConfirmDelete = async (e) => {
    let productToDelete: any = product;
    let requestBody = {
      id: productToDelete?.id
    };
    const productDeleted: any = await API.graphql(
      graphqlOperation(deleteProduct, { input: requestBody })
    );
    onDeleteItem(productToDelete);
    notification.open({
      message: `Producto Eliminado`,
      description: `El producto se ah Eliminado correctamente, ahora puedes continuar.`,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
    getProductList();
  };

  const cancel = (e) => {
    console.log(e);
  };

  const content = (
    <div>
      <div
        className="moreOptions"
        onClick={() => {
          setIsPreviewVisible(false);
          setIsEditModalVisible(true);
        }}
      >
        <EditOutlined key="edit" /> Editar
      </div>
      <br />
      <Popconfirm
        title="Seguro que quieres eliminar este producto?"
        onConfirm={onConfirmDelete}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <div className="moreOptions">
          <DeleteOutlined className="moreIcons" key="delete" />
          Eliminar
        </div>
      </Popconfirm>
    </div>
  );

  const EditProductModal = () => {

    const { getProductList } = useProduct();
    return (
      <Modal
        title={`Editar: ${product.code}`}
        visible={isEditModalVisible}
        onOk={() => setIsEditModalVisible(false)}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        {product.code && (
          <ManageProductComponent
            product={product}
            onUpdated={({ updatedProduct }) => {
              getProductList();
              setIsEditModalVisible(false);
            }}
          />
        )}
      </Modal>
    );
  };


  const ProductCard = () => {
    
  console.log('Product Card: Rendered');
    return (
      <Card
        title={product.code}
        headStyle={CardHeaderStyle}
        style={CardStyle}
      >
        {product.description && product.description.length > 30 && (
          <span className="NewLabel">NEW</span>
        )}
        <div
          onClick={() => {
            setIsPreviewVisible(true);
          }}
          className="LabelSection"
        >
          <ShowImageComponent item={product}></ShowImageComponent>
          <Row
            style={{
              borderTop: "1.5px solid rgb(205 199 168)",
              borderBottom: "1.5px solid rgb(205 199 168)",
            }}
          >
            {/* <Col span={24}>
              <label>
                <p className="Property">{product.description}</p>
              </label>
            </Col> */}
            <Col span={24}>
              <label>
                <p className="Property">Precio: </p>{" "}
                {product.publicPrice ? `$${product.publicPrice}` : "-"}{" "}
              </label>
            </Col>
          </Row>
        </div>
        {showOptions && (
          <div style={{ padding: 2 }}>
            <Row style={{ paddingTop: 3 }}>
              <Col span={24}>
                <AddToCartButton />
              </Col>
            </Row>
          </div>
        )}
      </Card>
    );
  };

const ProductModal = () => {
    
  const { setCustomProductQuantity, removeProductFromCart, cartList} = useShoppingCart();
  const isInCart = !!cartList.find((item) => item.product.id === product.id);
  const currentCartItem = cartList.find(
    (item) => item.product.id === product.id
  );
    return (
      <Modal
        visible={previewVisible}
        title={product.code}
        footer={null}
        style={{ top: 20, height: 400 }}
        onCancel={() => {
          setIsPreviewVisible(false);
        }}
      >
        <br />
        <ShowImageComponent item={product}></ShowImageComponent>
        <Collapse defaultActiveKey={["1", "2"]} expandIconPosition={"right"}>
          <Panel header="Descripcion" key="1" extra={<UnorderedListOutlined />}>
            <p>{product.description}</p>
          </Panel>
          {product.publicPrice && (
            <Panel
              header="Precio Público"
              key="2"
              extra={<DollarCircleOutlined />}
            >
              <p>${product.publicPrice}</p>
            </Panel>
          )}
          {user.isAdmin && product.privatePrice && (
            <Panel
              header="Precio Compra"
              key="3"
              extra={<EyeInvisibleOutlined />}
            >
              <p>${product.privatePrice}</p>
            </Panel>
          )}
        </Collapse>
        <Row style={{ padding: 5 }} gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
          {isInCart && (
            <Col span={user.isAdmin ? 6 : 8}>
              <Popconfirm
                title={`Estas seguro de quitar este producuto de tu bolsa?\n Tienes un total de ${currentCartItem.quantity}`}
                okText="Si, quitar todos"
                cancelText="No, seguir comprando"
                onConfirm={() => {
                  setCustomProductQuantity(product, 1);
                  removeProductFromCart(product);
                }}
              >
                <button style={{ width: "100%" }} className="ButtonDangerColor">
                  Quitar
                </button>
              </Popconfirm>
            </Col>
          )}
          <Col span={user.isAdmin ? (isInCart ? 14 : 20) : isInCart ? 16 : 24}>
            <AddToCartButton />
          </Col>

          {user.isAdmin && (
            <Popover
              content={content}
              title="Más opciones"
              placement="left"
              trigger="click"
            >
              <Col className="MoreOptionsButton" span={4}>
                <SettingOutlined style={{ padding: 5, fontSize: 30 }} />
              </Col>
            </Popover>
          )}
        </Row>
      </Modal>
    );
  };

  console.log('Product Card Container: Rendered');
  return (
    <>
      {
        <div className="ProductCart">
          {product && product.code && (
            <>
              <ProductCard/>
              <ProductModal/>
              <EditProductModal/>
            </>
          )}
        </div>
      }
    </>
  );
}
