/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      title
      details
      allowedRoles
      createdBy
      type
      relatedModel
      relatedId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      title
      details
      allowedRoles
      createdBy
      type
      relatedModel
      relatedId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      title
      details
      allowedRoles
      createdBy
      type
      relatedModel
      relatedId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSaleDetail = /* GraphQL */ `
  mutation CreateSaleDetail(
    $input: CreateSaleDetailInput!
    $condition: ModelSaleDetailConditionInput
  ) {
    createSaleDetail(input: $input, condition: $condition) {
      id
      total
      moneyReceived
      change
      Sales {
        nextToken
        startedAt
      }
      storeID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSaleDetail = /* GraphQL */ `
  mutation UpdateSaleDetail(
    $input: UpdateSaleDetailInput!
    $condition: ModelSaleDetailConditionInput
  ) {
    updateSaleDetail(input: $input, condition: $condition) {
      id
      total
      moneyReceived
      change
      Sales {
        nextToken
        startedAt
      }
      storeID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSaleDetail = /* GraphQL */ `
  mutation DeleteSaleDetail(
    $input: DeleteSaleDetailInput!
    $condition: ModelSaleDetailConditionInput
  ) {
    deleteSaleDetail(input: $input, condition: $condition) {
      id
      total
      moneyReceived
      change
      Sales {
        nextToken
        startedAt
      }
      storeID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSale = /* GraphQL */ `
  mutation CreateSale(
    $input: CreateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    createSale(input: $input, condition: $condition) {
      id
      price
      note
      description
      quantity
      saleDetailId
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSale = /* GraphQL */ `
  mutation UpdateSale(
    $input: UpdateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    updateSale(input: $input, condition: $condition) {
      id
      price
      note
      description
      quantity
      saleDetailId
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSale = /* GraphQL */ `
  mutation DeleteSale(
    $input: DeleteSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    deleteSale(input: $input, condition: $condition) {
      id
      price
      note
      description
      quantity
      saleDetailId
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    createStore(input: $input, condition: $condition) {
      id
      name
      location
      vendors
      SaleDetail {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      name
      location
      vendors
      SaleDetail {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      name
      location
      vendors
      SaleDetail {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

// BRAND - Table
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
  ) {
    createBrand(input: $input) {
      id
      name
    }
  }
`;

export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
  ) {
    updateBrand(input: $input) {
      id
      name
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
  ) {
    deleteBrand(input: $input) {
      id
      name
    }
  }
`;

// ProductType - Table

export const createProductType = /* GraphQL */ `
  mutation CreateProductType(
    $input: CreateProductTypeInput!
  ) {
    createProductType(input: $input) {
      id
      name
      createdAt
      updatedAt
      categoryId
    }
  }
`;

export const updateProductType = /* GraphQL */ `
  mutation UpdateProductType(
    $input: UpdateProductTypeInput!
  ) {
    updateProductType(input: $input) {
      id
      name
      category {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      categoryId
    }
  }
`;

export const deleteProductType = /* GraphQL */ `
  mutation DeleteProductType(
    $input: DeleteProductTypeInput!
  ) {
    deleteProductType(input: $input) {
      id
      name
      category {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      categoryId
    }
  }
`;

// Picture - Table

export const createPicture = /* GraphQL */ `
  mutation CreatePicture(
    $input: CreatePictureInput!
  ) {
    createPicture(input: $input) {
      id
      name
      file {
        bucket
        region
        key
      }
      productId
      productTypeId
    }
  }
`;
export const updatePicture = /* GraphQL */ `
  mutation UpdatePicture(
    $input: UpdatePictureInput!
  ) {
    updatePicture(input: $input) {
      id
      name
      file {
        bucket
        region
        key
      }
      productId
      productTypeId
    }
  }
`;
export const deletePicture = /* GraphQL */ `
  mutation DeletePicture(
    $input: DeletePictureInput!
  ) {
    deletePicture(input: $input) {
      id
      name
      file {
        bucket
        region
        key
      }
      productId
      productTypeId
    }
  }
`;

// Product - Table

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
  ) {
    createProduct(input: $input) {
      id
      code
      description
      publicPrice
      privatePrice
      pictures {
        items {
          id
          name
          file {
            bucket
            key
            region
          }
        }
      }
      brand {
        id
        name
        createdAt
        updatedAt
      }
      barcode
      productType {
        id
        name
        createdAt
        updatedAt
        categoryId
      }
      createdAt
      updatedAt
      brandId
      productTypeId
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
  ) {
    updateProduct(input: $input) {
      id
      code
      description
      publicPrice
      privatePrice
      barcode
      createdAt
      updatedAt
      brandId
      productTypeId
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
  ) {
    deleteProduct(input: $input) {
      id
      code
      description
      publicPrice
      privatePrice
      barcode
      createdAt
      updatedAt
      brandId
      productTypeId
    }
  }
`;

