import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import Amplify from "aws-amplify";
Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_appsync_region: process.env.REACT_APP_REGION,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_REGION,
  aws_user_files_s3_bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_apiKey: process.env.REACT_APP_API_KEY,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  aws_cognito_username_attributes: ["PHONE_NUMBER"],
  aws_cognito_signup_attributes: [
    "PHONE_NUMBER",
    "given_name",
    "family_name",
    "custom_value",
  ],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"]
});

document.title = `${process.env.REACT_APP_DISPLAY_NAME}`;

console.log("Render: Index");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
