import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { List, Empty, Card, Spin, Drawer, Row, Col } from 'antd';
import './StoreListComponent.css';
import { LoadingOutlined } from '@ant-design/icons';
import { SaleDetail, Store } from '../../models';
import { Button } from 'antd';
import { listStores } from '../../graphql/queries';
import { Link } from "react-router-dom";
import { DatePicker } from 'antd';

import { BarChartOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import * as GloblaFunctions from '../GlobalFunctionsComponent/GlobalFunctions';
import useUser from '../_Hooks/useUser';
const { RangePicker } = DatePicker;
const { Meta } = Card;

const spinIcon = <LoadingOutlined style={{ fontSize: 120 }} spin />;

export const StoreList = () => {

    const user = useUser();

    const initState: Store[] = [];
    const initSalesState: SaleDetail[] = [];
    const initSaleDetailState: SaleDetail = new Store({});
    const [storeList, setList] = useState(initState);
    const [salesList, setSalesList] = useState(initSalesState);
    const [isStoreListLoadedSuccessful, setIsStoreListLoadedSuccessful] = useState(false);
    let initialState: Store = new Store({});
    const [currentStore, setCurrentStore] = useState(initialState);
    const [currentSaleDetail, setCurrentSaleDetail] = useState(initSaleDetailState);
    const [visible, setVisible] = useState(false);
    const [isSalesDrawerVisible, setIsSalesDrawerVisible] = useState(false);
    let dateRangeInit: any = null;
    const [dateRange, setDateRange] = useState(dateRangeInit);
    const [totalSalesAmount, setTotalSalesAmount] = useState(0);

    useEffect(() => {
        getStoreList();
        return () => {
            sessionStorage.removeItem('currentPage')
        };
    }, [])


    const getStoreList = async () => {
        try {
            const storeListData: any = await API.graphql(graphqlOperation(listStores));
            let storeList: Store[] = storeListData.data.listStores.items;
            storeList = storeList.filter(
                (i: any) => i?._deleted !== true
            );
            storeList.forEach((store: any) => {
                store.SaleDetail = store.SaleDetail.items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                store.SaleDetail.forEach((saleDetail: any) => {
                    saleDetail.Sales = saleDetail.Sales.items;
                });
            });
            setList(storeList);
            setIsStoreListLoadedSuccessful(true);
        } catch (error) {
            setIsStoreListLoadedSuccessful(true);
            console.log(error)
            console.log('Error fetching the store')
        }
    }

    const showDrawer = (currentStore) => {
        setCurrentStore(currentStore);
        setSalesList(currentStore.SaleDetail);
        calculateTotalSalesAmount(currentStore.SaleDetail);
        setDateRange(null);
        setVisible(true);
    };

    const showSalesDrawer = (currentSaleDetail) => {
        setCurrentSaleDetail(currentSaleDetail);
        setIsSalesDrawerVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const onSalesDrawerClose = () => {
        setIsSalesDrawerVisible(false);
    };

    const onSalesDateChange = (moments, dateStrings) => {
        let saleList: any = currentStore.SaleDetail;
        setDateRange(moments)
        if (moments) {
            filterSales(dateStrings);
        } else {
            setSalesList(saleList)
            calculateTotalSalesAmount(saleList);
        }
    };

    const filterSales = (dateStrings) => {
        let saleList: any = currentStore.SaleDetail;
        try {
            let from = new Date(dateStrings[0]);
            let to = new Date(dateStrings[1]);
            from.setTime(from.getTime() + (6 * 60 * 60 * 1000))
            to.setTime(to.getTime() + (30 * 60 * 60 * 1000))
            saleList = saleList?.filter((sale: any) => new Date(sale.createdAt) >= from && new Date(sale.createdAt) <= to);
            setSalesList(saleList);
            calculateTotalSalesAmount(saleList);
            console.log(saleList)
            console.log(to)
            console.log(from)
        } catch (error) {
        }
    };

    const calculateTotalSalesAmount = (saleList) => {
        let total = 0;
        saleList.forEach(sale => {
            console.log(sale)
            total = total + sale.total
        });
        setTotalSalesAmount(total);
    }

    return (
        <>
            <div className="StoreListContainer">
                {isStoreListLoadedSuccessful ?
                    <>
                        {
                            storeList.length > 0 ?
                                <List
                                    grid={{ gutter: 10, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
                                    dataSource={storeList}
                                    pagination={{
                                        onChange: page => {
                                            //sessionStorage.setItem('currentPage', `${page}`);
                                            document.getElementById('appContentDiv')?.scroll({
                                                top: 0,
                                                behavior: 'smooth'
                                            });
                                        },
                                        pageSize: 24,
                                        hideOnSinglePage: true
                                    }}
                                    style={{
                                        margin: 5
                                    }}
                                    renderItem={(store: Store) => (
                                        <List.Item>
                                            <Card
                                                style={{ width: 350 }}
                                                cover={
                                                    <img
                                                        alt="example"
                                                        src="../images/empty-store-icon.png"
                                                    />
                                                }
                                                actions={[
                                                    <BarChartOutlined key="ellipsis" onClick={() => showDrawer(store)} />,
                                                ]}
                                            >
                                                <Meta
                                                    title={store.name}
                                                    description={store.location}
                                                />
                                                <p>Ventas: {store.SaleDetail?.length}</p>
                                            </Card>
                                        </List.Item>
                                    )}
                                /> :
                                <Empty
                                    image="../images/empty-store-icon.png"
                                    imageStyle={{
                                        marginTop: 50,
                                        height: 260,
                                    }}
                                    description={
                                        <span>
                                            Por el momento no hay Sucursales
                                        </span>
                                    }
                                >
                                    {(user.isAdmin) &&
                                        <Link to="/create-store"><Button type="primary">Agregar Nueva Sucursal</Button></Link>
                                    }
                                </Empty>
                        }
                    </>
                    :
                    <Spin style={{ marginTop: 150 }} tip="Cargando, por favor espere..." indicator={spinIcon} />

                }
            </div>
            <Drawer
                title={`${currentStore.name} - ${currentStore.location}`}
                placement="right"
                width={window.innerWidth / (isMobile ? 1 : 2)}
                onClose={onClose}
                visible={visible}
            >
                <>
                    <Row>
                        <Col span={12}>
                            <RangePicker
                                onChange={onSalesDateChange}
                                value={dateRange}
                            />
                        </Col>
                        <Col span={12}>
                            <div className='saleSubTitle'>Total: ${totalSalesAmount}</div>
                        </Col>
                    </Row>
                    {
                        salesList?.map((saleDetail: any) =>
                            <div className='saleDetailPanel' onClick={() => showSalesDrawer(saleDetail)} >
                                <Row>
                                    <Col span={12}>
                                        <p className='saleSubTitle'>Productos</p>
                                        <p>{saleDetail.Sales.length}</p>
                                    </Col>
                                    <Col span={12}>
                                        <p className='saleSubTitle'>Total</p>
                                        <p>${saleDetail?.total}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <p className='saleSubTitle'>Fecha</p>
                                        <p>{new Date(saleDetail?.createdAt).toLocaleDateString()}</p>
                                    </Col>
                                    <Col span={12}>
                                        <p className='saleSubTitle'>Hora</p>
                                        <p>{new Date(saleDetail?.createdAt).toLocaleTimeString()}</p>
                                    </Col>
                                </Row>

                            </div>)
                    }
                </>

                <Drawer
                    title={`Detalle de Venta`}
                    width={320}
                    closable={true}
                    onClose={onSalesDrawerClose}
                    visible={isSalesDrawerVisible}
                >
                    {
                        currentSaleDetail.Sales?.map(sale =>
                            <div className='salePanel'>
                                <p className='saleSubTitle'>Descripción</p>
                                <p>{sale?.description}</p>
                                <Row>
                                    <Col span={12}>
                                        <p className='saleSubTitle'>Precio</p>
                                        <p>${sale?.price}</p>
                                    </Col>
                                    <Col span={12}>
                                        <p className='saleSubTitle'>Cantidad</p>
                                        <p>{sale?.quantity}</p>
                                    </Col>
                                </Row>
                                <p className='saleSubTitle'>Total</p>
                                <p>${sale?.quantity && sale?.price && sale?.quantity * sale?.price}</p>
                            </div>)
                    }
                </Drawer>
            </Drawer>
        </>

    );
}