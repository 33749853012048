import { useEffect, useRef, useState } from 'react';
import './ManageProductComponent.css';
import { Form, Input, InputNumber, notification, Spin, Select, Row, Col, Modal } from 'antd';
import { Storage, API, graphqlOperation, } from "aws-amplify";
import { SmileOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { createProduct, updateProduct } from '../../graphql/mutations';
import { Product, ProductType } from '../../models';
import * as GloblaFunctions from '../GlobalFunctionsComponent/GlobalFunctions';
import useUser from '../_Hooks/useUser';
import { ManageProductTypeComponent } from '../ManageProductTypeComponentView/ManageProductTypeComponent';
import { UploadImage } from '../UploadImagesComponent/UploadImageComponent';
import { ManageBrandComponent } from '../ManageBrandComponentView/ManageBrandComponent';
import useBrand from '../_Hooks/useBrand';
import useProductType from '../_Hooks/useProductType';
import { PrimaryButtonStyle, PrimaryButtonTextStyle } from '../../enviroment/enviroment';

const { Option } = Select;

interface ManageProductProps {
  product?: Product
  onUpdated?: (updatedProduct) => void
}

export function ManageProductComponent({ product, onUpdated }: ManageProductProps) {

  const user = useUser();
  const { brands, getBrandList } = useBrand();
  const { productTypes } = useProductType();
  const uploadImageRef = useRef({ onSave: async (item) => { } });

  const [productForm] = Form.useForm();
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [isNewProductTypeVisible, setIsNewProductTypeVisible] = useState(false);
  const [isNewBrandVisible, setIsNewBrandVisible] = useState(false);
  const initialStateProduct: any = null;
  const [currentEditProduct, setCurrentEditProduct] = useState(initialStateProduct);


  useEffect(() => {
    if (product) {
      setCurrentEditProduct(product);
      productForm.setFieldsValue({
        product: product
      })
    }
  }, [product, productForm])

  const openSuccessNotification = () => {
    notification.open({
      message: `Producto ${currentEditProduct ? 'Actualizado' : 'Creado'}`,
      description:
        `El producto se ah ${currentEditProduct ? 'actualizado' : 'creado'} correctamente, ahora puedes continuar.`,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };


  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} es un campo necesario!',
    types: {
      number: '${label} no es un valor valido!',
    }
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = async ({ product }: any) => {
    try {
      setSaveInProcess(true);
      let requestBody = {
        ...product,
        id: currentEditProduct?.id
      }
      const newProductCreated: any = await API.graphql(graphqlOperation(currentEditProduct ? updateProduct : createProduct, { input: requestBody }));
      const productId = currentEditProduct ? newProductCreated.data.updateProduct.id : newProductCreated.data.createProduct.id;
      let pictures = { items: uploadImageRef.current.onSave({ productId: productId }) };
      setTimeout(() => {
        onUpdated && onUpdated({updatedProduct: {...newProductCreated.data.updateProduct, pictures}, createProduct: {...newProductCreated.data.createProduct, pictures}});
        resetComponent();
      }, 3000);
      currentEditProduct ?
        GloblaFunctions.addNewNotification(`Producto Actualizado`, `El producto ${newProductCreated.data.updateProduct.code} fue actualizado por ${user.username}`, ['admin', 'store_vendor', 'store_manager'], 'productNotification', 'Product', newProductCreated.data.updateProduct.id, user.email)
        :
        GloblaFunctions.addNewNotification(`Producto Nuevo`, `El producto ${newProductCreated.data.createProduct.code} fue agregado por ${user.username}`, ['admin', 'store_vendor', 'store_manager'], 'productNotification', 'Product', newProductCreated.data.createProduct.id, user.email);
        
    } catch (error) {
      console.log(error)
    }

  };

  const resetComponent = () => {
    openSuccessNotification();
    productForm.resetFields();
  }

  const AddNewProductTypeModal = () => {
    return (
      <Modal title={`Agregar Tipo de Producto`} visible={isNewProductTypeVisible} onCancel={() => setIsNewProductTypeVisible(false)} footer={null}>
        <ManageProductTypeComponent
          onCreated={(createdProductType) => {
            setIsNewProductTypeVisible(false);
            productForm.setFieldsValue({
              product: { ...product, productTypeId: createdProductType.id }
            })
          }} />
      </Modal>
    );
  }

  const AddBrandModal = () => {
    return (
      <Modal title={`Agregar Marca`} visible={isNewBrandVisible} onCancel={() => setIsNewBrandVisible(false)} footer={null}>
        <ManageBrandComponent
          onCreated={(createdBrand) => {
            console.log(createdBrand)
            setIsNewBrandVisible(false);
            productForm.setFieldsValue({
              product: { ...product, brandId: createdBrand.id }
            })
          }} />
      </Modal>
    );
  }

  return (
    <Spin spinning={saveInProcess}>
      <div className="addProductContent">

        <Form form={productForm} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
          <UploadImage
            currentItem={currentEditProduct}
            limit={5}
            ref={uploadImageRef}
          ></UploadImage>
          <Form.Item name={['product', 'code']} label="Código" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={['product', 'description']} label="Descripción" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Row>
            <Col span={23}>
              <Form.Item name={['product', 'productTypeId']} label="Tipo de producto" rules={[]}>
                <Select
                  placeholder="Elige un tipo de producto"
                  allowClear
                >
                  {productTypes && productTypes.map((productType: ProductType) => {
                    return <Option key={productType.id} value={productType.id}>{productType.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}>
              <PlusSquareOutlined className='AddIcon' onClick={() => setIsNewProductTypeVisible(true)} />
            </Col>
          </Row>
          <Row>
            <Col span={23}>
              <Form.Item name={['product', 'brandId']} label="Marca" rules={[]}>
                <Select
                  placeholder="Elige una marca"
                  allowClear
                >
                  {brands && brands.map((brand: ProductType) => {
                    return <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}>
              <PlusSquareOutlined className='AddIcon' onClick={() => setIsNewBrandVisible(true)} />
            </Col>
          </Row>
          <Form.Item name={['product', 'publicPrice']} label="Precio Venta" rules={[{ type: 'number' }]}>
            <InputNumber />
          </Form.Item>
          {user.isAdmin &&
            <Form.Item name={['product', 'privatePrice']} label="Precio Compra" rules={[{ type: 'number' }]}>
              <InputNumber />
            </Form.Item>
          }
          <Form.Item>
            <button className='ButtonPrimaryColor' style={PrimaryButtonStyle}>
            <div className="ButtonPrimaryTextColor" style={PrimaryButtonTextStyle}>{product ? 'Actualizar' : 'Agregar'}</div>
            </button>
          </Form.Item>
        </Form>
        <AddNewProductTypeModal></AddNewProductTypeModal>
        <AddBrandModal></AddBrandModal>
      </div>
    </Spin >
  );
}

