// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Notification, ProductType, Category, Picture, Brand, SaleDetail, Sale, Store, Product, S3Object } = initSchema(schema);

export {
  Notification,
  ProductType,
  Category,
  Picture,
  Brand,
  SaleDetail,
  Sale,
  Store,
  Product,
  S3Object
};