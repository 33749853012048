import { ShoppingOutlined } from "@ant-design/icons";
import "./CartListComponent.css";
import {
  CartHeaderStyle,
  CartHeaderTextStyle,
  HeaderIconStyle,
  HeaderTextStyle,
  PrimaryButtonStyle,
  PrimaryButtonTextStyle,
  SubHeaderStyle,
} from "../../enviroment/enviroment";
import { Badge, Button, Drawer, Empty, Spin } from "antd";
import { useState } from "react";
import { CartItemComponent } from "../CartItemComponentView/CartItemComponent";
import useShoppingCart from "../_Hooks/useShoppingCart";

import { LoadingComponent } from "../LoadingComponentView/LoadingComponent";

export function CartListComponent() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const CartItems = () => {
    const { cartList } = useShoppingCart();

    return (
      <>
        {cartList.length > 0 ? (
          <>
            <div className="CartItemsContainer">
              {cartList.map((cartItem) => {
                return (
                  <CartItemComponent cartItem={cartItem}></CartItemComponent>
                );
              })}
            </div>
          </>
        ) : (
          <Empty
            image="../images/empty_cart.png"
            imageStyle={{
              height: 150,
            }}
            description={<span>Tu lista esta vacia</span>}
          >
            <button
              className="ButtonPrimaryColor"
              style={PrimaryButtonStyle}
              onClick={onClose}
            >
              <div
                className="ButtonPrimaryTextColor"
                style={PrimaryButtonTextStyle}
              >
                Seguir buscando
              </div>
            </button>
          </Empty>
        )}
      </>
    );
  };

  const CartDrawer = () => {
    const { isAddingItem, cartList, getTotal } = useShoppingCart();

    return (
      <Drawer
        title={
          <div className="DrawerTitle" style={CartHeaderTextStyle}>
            Mi Bolsa
          </div>
        }
        headerStyle={SubHeaderStyle}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible && isAddingItem.id}
        bodyStyle={{
          padding: 0,
        }}
        footer={
          cartList.length > 0 && (
            <div className="CartDetailsContainer">
              <div className="TotalLabel">{`Total: $${getTotal()} mxn`}</div>
              <button
                className="ButtonPrimaryColor"
                style={PrimaryButtonStyle}
                onClick={onClose}
              >
                <div
                  className="ButtonPrimaryTextColor"
                  style={PrimaryButtonTextStyle}
                >
                  Realizar Pedido
                </div>
              </button>
            </div>
          )
        }
      >
        {!isAddingItem.code ? (
          <CartItems></CartItems>
        ) : (
          <LoadingComponent
            loadingText={"Actualizando tu bolsa..."}
          ></LoadingComponent>
        )}
      </Drawer>
    );
  };

  const CartIcon = () => {
    const { cartList } = useShoppingCart();

    return (
      <Badge count={cartList.length}>
        <ShoppingOutlined style={HeaderIconStyle} onClick={showDrawer} />
        <CartDrawer></CartDrawer>
      </Badge>
    );
  };

  return <CartIcon></CartIcon>;
}
