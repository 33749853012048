import { useEffect, useRef, useState } from 'react';
import './ManageProductTypeComponent.css';
import { Form, Input, notification, Spin, Select, Popconfirm } from 'antd';
import { Storage, API, graphqlOperation, } from "aws-amplify";
import { SmileOutlined } from '@ant-design/icons';
import { createProductType, deleteProductType, updateProductType } from '../../graphql/mutations';
import useUser from '../_Hooks/useUser';
import { ProductType } from '../../models';
import { onDeleteItem, UploadImage } from '../UploadImagesComponent/UploadImageComponent';
import { getProductType } from '../../graphql/queries';
import useProductType from '../_Hooks/useProductType';

const { Option } = Select;

interface ManageProductTypeProps {
  productType?: ProductType
  onCreated?: (createdProductType) => void,
  onUpdated?: (updatedProductType) => void,
  onDeleted?: (deletedProductType) => void,
}

export function ManageProductTypeComponent({ productType, onCreated, onUpdated, onDeleted }: ManageProductTypeProps) {

  const user = useUser();
  const { productTypes, setProductTypes } = useProductType();
  const uploadImageRef = useRef({ onSave: (item) => { } });

  const [productTypeForm] = Form.useForm();
  const [saveInProcess, setSaveInProcess] = useState(false);
  const initialStateProduct: any = null;
  const [currentEditProductType, setCurrentEditProductType] = useState(initialStateProduct);

  useEffect(() => {
    if (productType) {
      setCurrentEditProductType(productType);
      productTypeForm.setFieldsValue({
        productType: productType
      })
    }
  }, [productType, productTypeForm])


  const resetComponent = () => {
    setSaveInProcess(false);
    openSuccessNotification();
    productTypeForm.resetFields();
  }

  const openSuccessNotification = () => {
    notification.open({
      message: `Tipo de Producto ${currentEditProductType ? 'Actualizado' : 'Creado'}`,
      description:
        `El tipo de producto se ah ${currentEditProductType ? 'actualizado' : 'creado'} correctamente, ahora puedes continuar.`,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} es un campo necesario!',
    types: {
      number: '${label} no es un valor valido!',
    }
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = async ({ productType }: any) => {
    try {
      setSaveInProcess(true);
      let requestBody = {
        ...productType,
        id: currentEditProductType?.id
      }
      const newProductTypeCreated: any = await API.graphql(graphqlOperation(currentEditProductType ? updateProductType : createProductType, { input: requestBody }));
      let productTypeId = currentEditProductType ? newProductTypeCreated.data.updateProductType.id : newProductTypeCreated.data.createProductType.id;
      uploadImageRef.current.onSave({
        productTypeId: productTypeId
      });
      setTimeout(() => {
        getProductTypeById(productTypeId).then(({ data }: any) => {
          onUpdated && onUpdated(data.getProductType);
          onCreated && onCreated(data.getProductType);
          currentEditProductType ?
            setProductTypes(productTypes.map(item => {
              if (data.getProductType.id === item.id) {
                return data.getProductType;
              }
              return item
            }))
            :
            setProductTypes([...productTypes, data.getProductType]);
          resetComponent();
        })
      }, 3000);
    } catch (error) {
      console.log(error)
    }

  };

  const onConfirmDelete = async (e) => {
    let productTypeToDelete: any = currentEditProductType;
    let requestBody = {
      id: productTypeToDelete?.id,
      _version: productTypeToDelete._version
    }
    const productTypeDeleted: any = await API.graphql(graphqlOperation(deleteProductType, { input: requestBody }));
    onDeleted && onDeleted(productTypeDeleted.data.deleteProductType);
    onDeleteItem(productTypeToDelete);
    productTypes.splice(productTypes.findIndex(item => productTypeDeleted.data.deleteProductType.id === item.id), 1);
    setProductTypes(productTypes.map(item => {
      return item
    }));
    notification.open({
      message: `Tipo de Producto Eliminado`,
      description:
        `El tipo de producto se ah Eliminado correctamente, ahora puedes continuar.`,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  }

  const getProductTypeById = async (id) => {
    return await API.graphql(graphqlOperation(getProductType, {
      id
    }));
  }


  return (
    <Spin spinning={saveInProcess}>
      <div className="addProductContent">
        <Form {...layout} form={productTypeForm} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
          <UploadImage
            currentItem={currentEditProductType}
            aspect={6 / 8}
            ref={uploadImageRef}
          ></UploadImage>
          <Form.Item name={['productType', 'name']} label="Nombre" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
            <button className='ButtonPrimaryColor'>
              {productType ? 'Actualizar' : 'Agregar'}
            </button>
          </Form.Item>
        </Form>
      </div>
      {(user.isAdmin && productType) &&
        <Popconfirm
          title="Seguro que quieres eliminar este tipo de producto?"
          onConfirm={onConfirmDelete}
          onCancel={() => { }}
          okText="Yes"
          cancelText="No"
        >
          <button className='ButtonDangerColor'>
            Eliminar
          </button>
        </Popconfirm >
      }
    </Spin>
  );
}

