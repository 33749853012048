import { API, graphqlOperation } from "aws-amplify";
import { createContext, ReactNode, useEffect, useState } from "react";
import {
  listProductTypes,
  listProducts,
} from "../../graphql/queries";
import { Product } from "../../models";

type ProductProviderProps = {
  children: ReactNode;
};

type ProductContex = {
  getProductList: () => void;
  setProducts: (products: Product[]) => void;
  products;
};

const ProductContex = createContext({} as ProductContex);

export function ProductProvider({ children }: ProductProviderProps) {
  const initProductState: Product[] = [];
  const [products, setProductsList] = useState(initProductState);
  const setProducts = (products) => setProductsList(products);

  useEffect(() => {
    getProductList();
  }, []);

  
  const getProductList = async () => {
    try {
      const productListData: any = await API.graphql(
        graphqlOperation(listProducts, {
          limit: 1000,
        })
      );
      let productList: Product[] = productListData.data.listProduct.items;
      setProductsList(productList);
    } catch (error) {
      console.log(error);
      console.log("Error fetching the productList");
    }
  };

  return (
    <ProductContex.Provider
      value={{
        setProducts,
        getProductList,
        products
      }}
    >
      {children}
    </ProductContex.Provider>
  );
}

export default ProductContex;
