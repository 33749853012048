import { Authenticator, Button, Heading, useAuthenticator, useTheme, View, Image, Text, Theme, ThemeProvider } from "@aws-amplify/ui-react";
import './AuthComponent.css';

const labels = {
  header: `Bienvenido a ${process.env.REACT_APP_DISPLAY_NAME}!`,
  signIn: 'Iniciar Sesion',
  signUp: 'Crear cuenta',
  password: 'Contraseña',
  confirmPassword: 'Confirma tu Contraseña',
  resetPassword: '¿Olvidaste la contraseña?',
  rights: 'All Rights Reserved',
  backToSignIn: 'Ya tengo cuenta',
  newUser: 'Cuenta Nueva',
  phone: 'Escribe tu numero aquí',
  confirmSignUpHeader: 'Escribe tu codigo de confirmacion:'
}

const components = {
  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; {labels.rights}
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {labels.header}
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <button
            className="ExtraActions"
            onClick={toResetPassword}
          >
            {labels.resetPassword}
          </button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {labels.newUser}
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      const { tokens } = useTheme();
      return (
        <View textAlign="center">
          <button
            onClick={toSignIn}
            className="ExtraActions"
          >
            {
              labels.backToSignIn
            }
          </button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {labels.confirmSignUpHeader}
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {labels.confirmSignUpHeader}
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      dialCode: '+52',
      placeholder: labels.phone
    },
    password: {
      placeholder: labels.password
    },
  },
  signUp: {
    phone_number: {
      order: 1,
      dialCode: '+52',
      placeholder: labels.phone,
      isRequired: true,
    },
    email: {
      order: 2
    },
    given_name: {
      order: 3,
      placeholder: 'Nombre'
    },
    family_name: {  
      order: 4,
      placeholder: 'Apellido'
    },
    password: {
      order: 5,
      placeholder: labels.password
    },
    confirm_password: {
      order: 6,
      placeholder: labels.confirmPassword
    }
  },

}

export default function AuthComponent() {
  const { tokens } = useTheme();
  const theme: Theme = {
    name: 'Vale Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.neutral[20].value,
          },
          secondary: {
            value: tokens.colors.neutral[100].value,
          },
        },
        font: {
          interactive: {
            value: tokens.colors.teal[10].value,
          },
          secondary: {
            value: tokens.colors.white.value,
          }
        },
        border: {
          primary: {
            value: tokens.colors.black.value 
          },
          secondary: {
            value: tokens.colors.black.value 
          },
          focus: {
            value: tokens.colors.black.value 
          }
        },
        brand: {
          primary: {
            '10': tokens.colors.black,
            '20': tokens.colors.black,
            '40': tokens.colors.black,
            '60': tokens.colors.black,
            '80': tokens.colors.black,
            '90': tokens.colors.neutral[90],
            '100': tokens.colors.neutral[80],
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: tokens.colors.black.value,
              },
            },
            _hover: {
              color: {
                value: tokens.colors.neutral[40].value,
              },
            },
            _active: {
              color: {
                value: tokens.colors.black.value,
              },
            },
            _disabled: {
              color: {
                value: tokens.colors.white.value,
              },
            }
          },
        },
      },
    },
  };

  return (
    <div className="Container">
      <ThemeProvider theme={theme}>
        <Authenticator formFields={formFields} components={components}>
          {() => {
            window.location.replace(window.location.origin)
          }}
        </Authenticator>
      </ThemeProvider>

    </div>
  );
}