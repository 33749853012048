import { Route } from 'react-router-dom';
import { ProductList } from "./Components/ProductsListComponentView/ProductListComponent";
import { SaleComponent } from './Components/SaleComponentView/SaleComponent';
import { StoreList } from './Components/StoreListComponentView/StoreListComponent';
import AuthComponent from './Components/AuthComponentView/AuthComponent';
import { HomeComponent } from './Components/HomeComponentView/HomeComponent';
import useUser from './Components/_Hooks/useUser';
//import { NotFoundComponent } from './Components/NotFoundComponentView/NotFoundComponent';

export function MainRouter() {

    const user = useUser();
    return (
        <div>
            <Route exact path='/' component={HomeComponent} />
            <Route exact path='/products/:type?/:code?' component={ProductList} />
            <Route exact path='/auth' component={AuthComponent} />
            {user.isAdmin &&
                <>
                    <Route exact path='/make-sale' component={SaleComponent} />
                    <Route exact path='/stores' component={StoreList} />
                </>

            }
        </div>
    );
}