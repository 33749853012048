import { Table, Row, Col, Button, Modal, Form, Input, InputNumber, Select, Space, notification } from 'antd';
import { DeleteTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { createSaleDetail, createSale } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { Scanner } from '../ScannerComponentView/ScannerComponent';
import { isMobile } from 'react-device-detect';
import { BarcodeOutlined, PictureOutlined } from '@ant-design/icons';
import './SaleComponent.css';
import { listProducts, listStores } from '../../graphql/queries';
import { Product, Store } from '../../models';
import { ProductCard } from '../ProductCardComponentView/ProductCardComponent';
import * as GloblaFunctions from '../GlobalFunctionsComponent/GlobalFunctions';
import useUser from '../_Hooks/useUser';

export function SaleComponent() {
  const user = useUser();
  let initialCurrentProductState: Product = new Product({});
  const [currentProduct, setCurrentProduct] = useState(initialCurrentProductState);
  const initialStateProductsToSaleList: any = [];
  const [productsToSaleList, setProductsToSaleList] = useState(initialStateProductsToSaleList);
  const initState: Store[] = [];
  const [storesList, setStoresList] = useState(initState);
  const [saleTotal, setSaleTotal] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalProductPreviewVisible, setIsModalProductPreviewVisible] = useState(false);
  const [isSaleModalVisible, setIsSaleModalVisible] = useState(false);
  const [saleForm] = Form.useForm();
  const [completeSaleForm] = Form.useForm();
  const { Option } = Select;
  const columns = [
    {
      title: <><PlusCircleTwoTone className='addSaleButton' onClick={() => {
        setIsModalVisible(true);
        saleForm.setFieldsValue({
          sale: {
            quantity: 1
          }
        })
      }} /></>,
      dataIndex: 'product',
      key: 'product',
      width: '5%',
      render: product => <>
        {
          product && product.pictures.items.length > 0 && <PictureOutlined className='addSaleButton' onClick={() => showProductPreview(product)} />
        }
      </>
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      width: '54%',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      width: '12%',
      render: price => `$ ${price}`,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
      editable: true,
      width: '12%',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: '12%',
      render: total => `$ ${total}`,
    },
    {
      title: '',
      dataIndex: 'key',
      key: 'delete',
      width: '5%',
      render: key => <DeleteTwoTone className='addSaleButton' style={{ fontSize: 20 }} twoToneColor="#9e2626" onClick={() => removeSaleItem(key)} />,
    },
  ];

  useEffect(() => {
    const onLoadComponent = async () => {
      const decryptedStoreList = GloblaFunctions.getSessionStorageItem('storeList')
      if (decryptedStoreList) {
        setStoresList(decryptedStoreList);
      } else {
        getStoresList();
      }
    }

    onLoadComponent();
    return () => {

    }
  }, [])

  const showProductPreview = (product) => {
    setIsModalProductPreviewVisible(true);
    setCurrentProduct(product);
  }

  const getStoresList = async () => {
    try {
      let filter = user.isAdmin ? null : { vendors: { contains: user.email } };
      const storeListData: any = await API.graphql(graphqlOperation(listStores, {
        filter: filter
      }));
      let storesList: Store[] = storeListData.data.listStores.items;
      storesList = storesList.filter(
        (i: any) => i?._deleted !== true
      );
      setStoresList(storesList);
      GloblaFunctions.setSessionStorageItem('storeList', storesList);
    } catch (error) {
      console.log(error)
      console.log('Error fetching the stores')
    }
  }

  const addNewSaleItem = () => {
    saleForm.validateFields().then(() => {
      let newSale = saleForm.getFieldsValue().sale;
      setProductsToSaleList([...productsToSaleList, {
        ...newSale,
        key: productsToSaleList.length + 1,
        total: newSale.price * newSale.quantity
      }])
      setSaleTotal(saleTotal + (newSale.price * newSale.quantity))
      saleForm.resetFields();
      setIsModalVisible(false);
      setTimeout(() => {
        barcodeAutoFocus();
      }, 300);
    })
  }
  const finishSale = () => {
    completeSaleForm.validateFields().then(async () => {
      let saleDetails = completeSaleForm.getFieldsValue().saleDetails;
      let requestBody = {
        ...saleDetails
      };
      const newSaleDetailCreated: any = await API.graphql(graphqlOperation(createSaleDetail, { input: requestBody }));
      const createdSaleDetail = newSaleDetailCreated.data.createSaleDetail;
      productsToSaleList.forEach(async (item) => {
        const { description, price, quantity, productID } = item;
        const newSaleCreated: any = await API.graphql(graphqlOperation(createSale, {
          input: {
            description,
            price,
            quantity,
            saleDetailId: createdSaleDetail.id,
            productID: productID,
          }
        }));
      });
      setIsSaleModalVisible(false);
      completeSaleForm.resetFields();
      setProductsToSaleList([]);
      setSaleTotal(0);
      notification.success({
        message: `Venta Registrada`,
        description:
          `La venta fue completada correctamente, ya puedes continuar.`,
      })
      const store = storesList.find(item => item.id === createdSaleDetail.storeID);
      let notificationDetail = `Venta ${store?.name} - ${store?.location} con Total de $${createdSaleDetail.total}, Productos: ${productsToSaleList.length} por ${user.username}`;
      GloblaFunctions.addNewNotification('Venta Registrada', notificationDetail, ['admin'], 'saleNotification', 'SaleDetail', createdSaleDetail.id, user.email);
      setTimeout(() => {
        barcodeAutoFocus();
      }, 300);
    });
  }

  const removeSaleItem = (key) => {
    let saleToRemove = productsToSaleList.find(item => item.key === key);
    let saleToRemoveIndex = productsToSaleList.findIndex(item => item.key === key);
    let newProductsToSaleList = productsToSaleList;
    newProductsToSaleList.splice(saleToRemoveIndex, 1);
    setProductsToSaleList([...newProductsToSaleList]);
    setSaleTotal(saleTotal - (saleToRemove.price * saleToRemove.quantity))
  }

  const handleCancelAdd = () => {
    saleForm.resetFields();
    setIsModalVisible(false);
    setTimeout(() => {
      barcodeAutoFocus();
    }, 300);
  }

  const handleCancelCompleteSale = () => {
    completeSaleForm.resetFields();
    setIsSaleModalVisible(false);
    setTimeout(() => {
      barcodeAutoFocus();
    }, 300);
  }

  const openSaleModal = () => {
    setIsSaleModalVisible(true);
    completeSaleForm.setFieldsValue({
      saleDetails: {
        total: saleTotal,
        storeID: storesList[0].id
      }
    })
  }

  const calculateChange = (moneyReceived) => {
    completeSaleForm.setFieldsValue({
      saleDetails: {
        change: moneyReceived - saleTotal
      }
    })
  }

  const onCompleted = async ({ codeResult: { code } }: any) => {
    try {
      const productListData: any = await API.graphql(graphqlOperation(listProducts, {
        filter: { code: { eq: code } },
        limit: 10000
      }));
      let product = productListData.data.listProducts.items.find(item => item._deleted === null);
      const { id, description, publicPrice } = product;
      let productIndex = productsToSaleList.findIndex(item => item.productID === id)
      if (productIndex === -1) {
        setProductsToSaleList([...productsToSaleList, {
          key: productsToSaleList.length + 1,
          description,
          price: publicPrice,
          quantity: 1,
          productID: id,
          total: publicPrice * 1,
          product
        }])
        setSaleTotal(saleTotal + publicPrice)
      } else {
        productsToSaleList[productIndex].quantity++;
        productsToSaleList[productIndex].total = productsToSaleList[productIndex].price * productsToSaleList[productIndex].quantity;
        setProductsToSaleList([...productsToSaleList]);
        setSaleTotal(saleTotal + publicPrice)
      }
    } catch (err) {
      notification['error']({
        message: `El Producto no existe.`,
        description:
          `El producto con codigo "${code}", no fue encontrado.`,
      });
    }
  }

  const barcodeAutoFocus = () => {
    !isMobile && document.getElementById("SearchbyScanning")?.focus()
  }

  const onKeyPressBarcode = (event) => {
    if (event.keyCode === 13) {
      if (isSaleModalVisible) {
        notification['error']({
          message: `No permitido.`,
          description:
            `No puedes escanear más productos en esta parte de la venta.`,
        });
      } else {
        onCompleted({ codeResult: { code: event.target.value } });
      }
      setTimeout(() => {
        event.target.value = '';
      }, 200);
    }
  }
  return (
    <div className="SaleContainer" >
      <Row>
        <Col className='SubTitle' style={{ minHeight: '3vh', paddingBottom: 10 }} span={24} >
          <span >Realizar Venta</span>
        </Col>
        <Col span={24} >
          <Space style={{ marginBottom: 16 }}>
            <Scanner onCompleted={onCompleted} CustomItem={onClick =>
              <Button onClick={onClick}>
                <BarcodeOutlined
                  style={{
                    fontSize: 20,
                    color: '#1890ff',
                    bottom: 3,
                    position: 'relative'
                  }}
                />
              </Button>
            }></Scanner>
            <div>
              <input
                autoFocus={!isMobile}
                disabled={isModalVisible || isSaleModalVisible}
                placeholder={'Escanea tu producto...'}
                id='SearchbyScanning'
                className='SearchInput'
                onKeyDown={onKeyPressBarcode}
              // onBlur={barcodeAutoFocus}
              />
            </div>
          </Space>
        </Col>
        <Col style={{ height: '78vh', overflow: 'hidden', marginBottom: 10 }} span={24} >
          <Table
            columns={columns}
            dataSource={productsToSaleList}
            pagination={{ pageSize: 100, hideOnSinglePage: true }}
            scroll={{ y: '50vh', x: '98vh' }}
            footer={() =>
              <>
                <Row>
                  <Col span={18} >
                    <div className='actionsContainter'>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>{`Total: $${saleTotal}`}</span>
                    </div>
                  </Col>
                  <Col span={6} >
                    <div className='actionsContainter'>
                      <Button disabled={!(productsToSaleList.length > 0)} type="primary" onClick={openSaleModal} >Continuar</Button>
                    </div>
                  </Col>
                </Row>
              </>
            } />
        </Col>
      </Row>
      <Modal visible={isModalVisible}
        onCancel={handleCancelAdd}
        style={{ top: 20 }}
        title='Agregar otro Producto'
        footer={[
          <Button type="primary" danger onClick={handleCancelAdd}>
            Cancelar
          </Button>,

          <Button type="primary" onClick={() => addNewSaleItem()}>Agregar</Button>
        ]}
      >
        <Form form={saleForm}>
          <Form.Item name={['sale', 'description']} rules={[{ required: true, min: 6, message: 'Introduce una descripción' }]} label="Descripción">
            <Input placeholder="Escribe la descripción del producto" />
          </Form.Item>
          <Form.Item name={['sale', 'price']} label="Precio" rules={[{ required: true, message: 'Introduce un precio valido', type: 'number', min: 0.1 }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name={['sale', 'quantity']} label="Cantidad" rules={[{ required: true, message: 'Introduce una cantidad valida', type: 'number', min: 0.1 }]}>
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
      <Modal className="completeSaleModal" visible={isSaleModalVisible}
        onCancel={handleCancelCompleteSale}
        title='Finalizar Venta'
        closable={false}
        style={{ top: 20 }}
        footer={[
          <Button type="primary" danger onClick={handleCancelCompleteSale}>
            Regresar
          </Button>,

          <Button type="primary" onClick={() => finishSale()}>Terminar</Button>
        ]}
      >
        <Form form={completeSaleForm}>
          <Form.Item name={['saleDetails', 'total']} label="Total">
            <InputNumber disabled={true} />
          </Form.Item>
          <Form.Item name={['saleDetails', 'moneyReceived']} label="Dinero recibido" rules={[{ required: true, message: 'Dinero insuficiente', type: 'number', min: saleTotal }]}>
            <InputNumber onChange={calculateChange} />
          </Form.Item>
          <Form.Item name={['saleDetails', 'change']} label="Cambio">
            <InputNumber disabled={true} />
          </Form.Item>
          {storesList && <Form.Item name={['saleDetails', 'storeID']} label="Sucursal">
            <Select>
              {storesList.map(store => <Option key={store.id} value={store.id}>{`${store.name} - ${store.location}`}</Option>)}
            </Select>
          </Form.Item>}
        </Form>
      </Modal>
      <Modal className="previewProductModal" visible={isModalProductPreviewVisible}
        style={{ top: 20 }}
        onCancel={() => setIsModalProductPreviewVisible(false)}
        footer={null}
        bodyStyle={{ padding: 0 }}>
        <ProductCard
          product={currentProduct}
          showOptions={false}
        />
      </Modal>
    </div >

  );
}

