import "./UploadImageComponent.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { createPicture, deletePicture } from "../../graphql/mutations";

interface ManageProductProps {
  currentItem?: any;
  limit?: number;
  aspect?: number;
}

export const onDeleteItem = (item) => {
  item.pictures &&
    item.pictures.items.forEach(async (image: any) => {
      const pictureDeleted: any = await API.graphql(
        graphqlOperation(deletePicture, {
          input: {
            id: image?.id,
            _version: image._version,
          },
        })
      );
      const { key } = pictureDeleted.data.deletePicture.file;
      Storage.remove(key).then((res) => {});
    });
};

export const UploadImage = forwardRef(
  ({ currentItem, limit, aspect }: ManageProductProps, ref) => {
    const [currentEditItem, setCurrentEditItem] = useState<any>();

    const fileListInitState: any[] = [];
    const [fileList, setFileList] = useState(fileListInitState);
    const filesToUploadInitState: File[] = [];
    const filesToDeleteInitState: File[] = [];
    const [filesToUpload, setFilesToUpload] = useState(filesToUploadInitState);
    const [filesToDelete, setFilesToDelete] = useState(filesToDeleteInitState);

    useImperativeHandle(ref, () => ({
      onSave: (item) => {
        let pictures = [];
        if(filesToUpload.length > 0){
          pictures = saveImages(item);
        }
        filesToDelete.length > 0 &&
          filesToDelete.forEach(async (image: any) => {
            const pictureDeleted: any = await API.graphql(
              graphqlOperation(deletePicture, {
                input: {
                  id: image?.uid,
                },
              })
            );
            const { key } = pictureDeleted.data.deletePicture.file;
            Storage.remove(key)
              .then((res) => {})
              .catch((err) => console.log(err));
          });
          return pictures;
      },
    }));

    useEffect(() => {
      setFileList([]);
      currentItem && setCurrentEditItem(currentItem);
    }, [currentItem]);

    useEffect(() => {
      const handleChangeEditProduct = async () => {
        let pictures: any = currentEditItem && currentEditItem.pictures;
        if (pictures && pictures.items.length > 0) {
          let list: any = [];
          // Remove deleted pictures
          const filteredPictures = pictures.items.filter(
            (i: any) => i?._deleted !== true
          );
          filteredPictures.forEach((image, index) => {
            Storage.get(image.file.key).then((url) => {
              list.push({
                uid: image.id,
                name: image.name,
                status: "done",
                url,
              });
              if (filteredPictures.length === index + 1) {
                setFileList(list);
              }
            });
          });
        }
      };

      handleChangeEditProduct();

      return () => {};
    }, [currentEditItem]);

    const saveImages = (item: any) => {
      let images: any = [];
      filesToUpload.forEach((file: File) => {
        let name = `${new Date().getTime()} - ${file.name}`;
        Storage.put(name, file, {
          level: "public",
          contentType: file.type
        })
          .then((result) => {
            const image = {
              ...item,
              name: name,
              file: {
                bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
                region: process.env.REACT_APP_REGION,
                key: name,
              },
            };
            addImageToDb(image);
            images.push(image);
          })
          .catch((err) => console.error(err));
      });
      return images;
    };

    const addImageToDb = async (image) => {
      try {
        await API.graphql(graphqlOperation(createPicture, { input: image }));
      } catch (error) {
        console.error(error);
      }
    };

    const uploadImage = async (options) => {
      const { onSuccess, onError, file } = options;
      let fileIndex = filesToUpload.findIndex(
        (item) => item.name === file.name
      );
      if (fileIndex) {
        setFilesToUpload(filesToUpload.concat([file]));
        onSuccess("Ok");
      } else {
        onError("Error de archivo duplicado", "El archivo ya existe.");
      }
    };

    const onChange = ({ fileList: newFileList }) => {
      setFileList(newFileList);
    };

    const onRemove = (file) => {
      filesToUpload.splice(
        filesToUpload.findIndex((item: any) => item.uid === file.uid),
        1
      );
      setFilesToUpload(filesToUpload);
      !file.percent && filesToDelete.push(file);
      setFilesToDelete(filesToDelete);
    };
    const onPreview = async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    };

    return (
      <ImgCrop aspect={aspect} rotate>
        <Upload
          customRequest={uploadImage}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onRemove={onRemove}
          onPreview={onPreview}
        >
          {fileList.length < (limit ? limit : 1) && "+ Upload"}
        </Upload>
      </ImgCrop>
    );
  }
);
