import { MainRouter } from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import HeaderComponent from "./Components/HeaderComponentView/HeaderComponent";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import UserContext from "./Components/_Contexts/UserContext";
import { ShoppingCartProvider } from "./Components/_Contexts/ShoppingCartContext";
import { SearchProvider } from "./Components/_Contexts/SearchContex";
import { ProductProvider } from "./Components/_Contexts/ProductContext";
import { BrandProvider } from "./Components/_Contexts/BrandContext";
import { ProductTypeProvider } from "./Components/_Contexts/ProductTypeContex";
import AuthComponent from "./Components/AuthComponentView/AuthComponent";
import { LoadingComponent } from "./Components/LoadingComponentView/LoadingComponent";
import { AppContainerStyle, FooterStyle } from "./enviroment/enviroment";

export interface User {
  userId?: string;
  email?: string;
  username?: string;
  phone?: string;
  picture?: string;
  groups?: any;
  isAdmin?: boolean;
  isStoreManager?: boolean;
  isStoreVendor?: boolean;
}

function App() {
  const AppComponent = () => {
    console.log("Render: AppComponent ");
    const { user } = useAuthenticator((context) => [context.user]);

    let initialStateUser: User = {};
    const [currentUser, setCurrentUser] = useState(initialStateUser);
    const [isLoginChecked, setIsLoginChecked] = useState(false);

    useEffect(() => {
      console.log("EFFECT");
      !currentUser?.userId && checkUser();
    }, [user]);

    const checkUser = async () => {
      console.log("CHECK USER TRIGGERED");
      Auth.currentAuthenticatedUser().then(
        ({ username, attributes, signInUserSession }) => {
          let currentUser: User = {
            userId: username,
            email: attributes.email,
            groups: signInUserSession.accessToken.payload["cognito:groups"],
            username: `${attributes.given_name} ${attributes.family_name}`,
            phone: attributes.phone_number,
            picture:
              "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
            isAdmin:
              signInUserSession.accessToken.payload["cognito:groups"]?.includes(
                "admin"
              ),
            isStoreManager:
              signInUserSession.accessToken.payload["cognito:groups"]?.includes(
                "store_manager"
              ),
            isStoreVendor:
              signInUserSession.accessToken.payload["cognito:groups"]?.includes(
                "store_vendor"
              ),
          };
          setCurrentUser(currentUser);
        },
        (err) => {
          console.log("The user is not authenticated");
          console.log(user);
          setTimeout(() => {
            setIsLoginChecked(true);
          }, 1200);
        }
      );
    };

    return (
      <div className="App" style={AppContainerStyle}>
          <Router>
            <UserContext.Provider value={currentUser}>
              <HeaderComponent />
              <div className="AppContent">
                <MainRouter></MainRouter>
              </div>
              <div className="center" style={FooterStyle}>v1.0.0</div>
            </UserContext.Provider>
          </Router>
      </div>
    );
  };

  console.log("Render: App");
  return <AppComponent />;
}

export default () => (
  <Authenticator.Provider>
    <SearchProvider>
      <BrandProvider>
        <ProductTypeProvider>
          <ProductProvider>
            <ShoppingCartProvider>
              <App />
            </ShoppingCartProvider>
          </ProductProvider>
        </ProductTypeProvider>
      </BrandProvider>
    </SearchProvider>
  </Authenticator.Provider>
);
