import { Menu, Modal } from "antd";
import { Avatar } from "antd";
import {
  ShopOutlined,
  PlusSquareOutlined,
  LoginOutlined,
  UserOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { Drawer } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { HeaderIconStyle } from "../../enviroment/enviroment";
import useUser from "../_Hooks/useUser";
import { ManageProductComponent } from "../ManageProductComponentView/ManageProductComponent";
import useProduct from "../_Hooks/useProduct";

const { SubMenu } = Menu;

export function UserMenuComponent() {
  const user = useUser();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [isAddProductVisible, setAddProductVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleClick = (e) => {
    console.log("click ", e);
  };

  const AddNewProductModal = () => {
    
    const { getProductList } = useProduct();

    return (
      <Modal
        title={`Agregar Nuevo producto`}
        visible={isAddProductVisible}
        onCancel={() => setAddProductVisible(false)}
        footer={null}
      >
        <ManageProductComponent
          onUpdated={({createProduct}) => {
            getProductList();
            setAddProductVisible(false);
          }}
        />
      </Modal>
    );
  };

  return (
    <div>
      {user ? (
        <div>
          {user.userId ? (
            <>
              <div style={{ cursor: "pointer" }} onClick={showDrawer}>
                {/* <Avatar src={user.picture} /> */}
                <UserOutlined style={HeaderIconStyle} />
              </div>
            </>
          ) : (
            <>
              <Link to="/auth">
                <UserOutlined style={HeaderIconStyle} />
              </Link>
            </>
          )}

          <Drawer
            title={
              <>
                <Avatar src={user.picture} />
                <> {user.username}</>
              </>
            }
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            bodyStyle={{
              padding: 0,
            }}
          >
            <Menu
              onClick={handleClick}
              style={{}}
              defaultSelectedKeys={[location.pathname]}
              defaultOpenKeys={["menu", "other"]}
              mode="inline"
            >
              <SubMenu key="menu" icon={<ShopOutlined />} title="Menu">
                {(user.isAdmin || user.isStoreManager) && (
                  <Menu.Item key="/stores" onClick={onClose}>
                    <Link to="/stores">Sucursales</Link>
                  </Menu.Item>
                )}
                {(user.isAdmin ||
                  user.isStoreManager ||
                  user.isStoreVendor) && (
                  <Menu.Item key="/make-sale" onClick={onClose}>
                    <Link to="/make-sale">Realizar venta</Link>
                  </Menu.Item>
                )}
                {(user.isAdmin || user.isStoreManager) && (
                  <Menu.Item
                    key="/create-product"
                    onClick={() => {
                      setAddProductVisible(true);
                      setVisible(false);
                    }}
                  >
                    Agregar producto
                  </Menu.Item>
                )}
              </SubMenu>
              <SubMenu key="other" icon={<PlusSquareOutlined />} title="Más">
                <Menu.Item
                  key="5"
                  onClick={() => {
                    Auth.signOut().then(() => window.location.reload());
                    sessionStorage.clear();
                  }}
                >
                  <LoginOutlined style={{ bottom: 3, position: "relative" }} />
                  <> Cerrar Sesión</>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Drawer>
          <AddNewProductModal />
        </div>
      ) : (
        <>
          <LoadingOutlined style={HeaderIconStyle} spin />
        </>
      )}
    </div>
  );
}
