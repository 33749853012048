import { API, graphqlOperation } from "aws-amplify";
import { createContext, ReactNode, useEffect, useState } from "react";
import {
  listBrand
} from "../../graphql/queries";
import { Brand } from "../../models";

type BrandProviderProps = {
  children: ReactNode;
};

type BrandContex = {
  getBrandList: () => void;
  setBrands: (brands: Brand[]) => void;
  brands;
};

const BrandContex = createContext({} as BrandContex);

export function BrandProvider({ children }: BrandProviderProps) {
  // Brand Table
  const initState: Brand[] = [];
  const [brands, setBrandsList] = useState(initState);
  const setBrands = (brands) => setBrandsList(brands);

  useEffect(() => {
    getBrandList();
  }, []);

  const getBrandList = async () => {
    try {
      const productTypeListData: any = await API.graphql(
        graphqlOperation(listBrand)
      );
      let productTypeList: Brand[] = productTypeListData.data.listBrand.items;
      setBrandsList(productTypeList);
    } catch (error) {
      console.log(error);
      console.log("Error fetching the productTypeList");
    }
  };

  

  return (
    <BrandContex.Provider
      value={{
        setBrands,
        getBrandList,
        brands,
      }}
    >
      {children}
    </BrandContex.Provider>
  );
}

export default BrandContex;
