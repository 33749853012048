import { createContext, ReactNode, useState } from "react";

type SearchProviderProps = {
    children: ReactNode
}

type SearchContex = {
    setSearchText: (text: string) => void,
    setIsActive: (value: boolean) => void,
    isSearchActive,
    searchText
}

const SearchContex = createContext({} as SearchContex);

export function SearchProvider({ children }: SearchProviderProps) {
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchText, setText] = useState('');

    const setIsActive = (value) => setIsSearchActive(value);
    const setSearchText = (text) => setText(text);
    
    return (
        <SearchContex.Provider value={{
            setSearchText,
            setIsActive,
            isSearchActive,
            searchText
        }}>
            {children}
        </SearchContex.Provider>
    )
}


export default SearchContex;