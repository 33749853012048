import { useEffect, useState } from 'react';
import Quagga from 'quagga';
import { Button, Modal } from 'antd';

import './ScannerComponent.css';
import { isMobile } from 'react-device-detect';

interface ScannerProps {
    onCompleted: (code: string) => void,
    CustomItem: Function
}

export const Scanner = ({ onCompleted, CustomItem }: ScannerProps) => {

    const [isModalScannerVisible, setIsModalScannerVisible] = useState(false);
    const [isChangingCam, setIsChangingCam] = useState(false);
    const [backCamId, setBackCamId] = useState('');
    const initialVideoDevicesState: MediaDeviceInfo[] = [];
    const [videoDevices, setVideoDevices] = useState(initialVideoDevicesState);

    useEffect(() => {
        getCurrentVideoDevices();
    }, [])

    const getCurrentVideoDevices = () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            //alert("Doesn't work");
            return;
        }
        navigator.mediaDevices.enumerateDevices()
            .then(function (devices) {
                let videoDevices: any = [];
                devices.forEach(function (device) {
                    if (device.kind === "videoinput") {
                        videoDevices.push(device);
                    }
                });
                setVideoDevices(videoDevices);
                setBackCamId(videoDevices[0].deviceId)
            })
            .catch(function (err) {
                //alert("Error");
            });
    }

    const initScanner = (onDetected, deviceId?) => {
        deviceId = deviceId ? deviceId : backCamId;
        try {
            Quagga.init(
                {
                    inputStream: {
                        name: "Live",
                        type: "LiveStream",
                        target: document.querySelector('#scanner-container'),
                        constraints: {
                            width: isMobile ? window.innerWidth - 20 : 520,
                            height: isMobile ? window.innerWidth - 20 : 520,
                            
                            deviceId: deviceId
                        }
                    },
                    decoder: {
                        readers: ['code_128_reader'],
                        debug: {
                            showCanvas: true,
                            showPatches: true,
                            showFoundPatches: true,
                            showSkeleton: true,
                            showLabels: true,
                            showPatchLabels: true,
                            showRemainingPatchLabels: true,
                            boxFromPatches: {
                                showTransformed: true,
                                showTransformedBox: true,
                                showBB: true
                            }
                        }
                    },
                },
                function (err: any) {
                    if (err) {
                        return console.log(err)
                    }
                    console.log("Initialization finished. Ready to start");
                    Quagga.start()

                },
            )
            Quagga.onDetected(code => {
                onDetected(code);
                handleCancel();
            })
            setTimeout(() => {
                setIsChangingCam(false);
            }, 1500);
        } catch (error) {
            console.log(error)
        }
    }

    const handleCancel = () => {
        setIsModalScannerVisible(false);
        Quagga.offDetected();
        Quagga.stop();
    };

    const showModal = () => {
        try {
            setIsModalScannerVisible(true);
            setIsChangingCam(true);
            setTimeout(() => {
                initScanner(onCompleted);
            }, 1);
        } catch (error) {
        }
    };

    const changeVideoDevice = () => {
        setIsChangingCam(true);
        Quagga.offDetected();
        Quagga.stop();
        setTimeout(() => {
            let currentIndex = videoDevices.findIndex(device => device.deviceId === backCamId);
            if (currentIndex >= (videoDevices.length - 1)) {
                setBackCamId(videoDevices[0].deviceId);
                initScanner(onCompleted, videoDevices[0].deviceId);
            } else {
                let newId = videoDevices[currentIndex + 1].deviceId;
                setBackCamId(newId);
                initScanner(onCompleted, newId)
            }
        }, 1);
    };

    return <>
        {CustomItem(showModal)}
        <Modal className="scannerModal" visible={isModalScannerVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            bodyStyle={{ padding: 0 }}
            footer={[
                <>
                    {videoDevices.length > 1 &&
                        <Button type="primary" disabled={isChangingCam} onClick={changeVideoDevice}>
                            Cambiar Camara
                        </Button>}
                </>,
                <Button type="primary" disabled={isChangingCam} danger onClick={handleCancel}>
                    Cancelar
                </Button>
            ]}
        >
            <div id="interactive" className="viewport" style={{ position: 'absolute'}} />
        </Modal>

    </>


}
