import { createContext, ReactNode, useState } from "react";
import { Product } from "../../models";
import { CartItem } from "../CartItemComponentView/CartItemComponent";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { NotificationPlacement } from "antd/lib/notification";

type ShoppingCartProviderProps = {
  children: ReactNode;
};

type ShoppingCartContext = {
  addProductToCart: (product: Product) => void;
  removeProductFromCart: (product: Product) => void;
  setCustomProductQuantity: (product: Product, quantity) => void;
  getTotal: () => number;
  cartList;
  isAddingItem;
};

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [cartList, setCartList] = useState<CartItem[]>([]);
  const [isAddingItem, setIsAddingItem] = useState(new Product({}));
  const notificationPlacement: NotificationPlacement = "bottomRight";

  const errorPriceNotification = () => {
    notification.error({
      message: `No es tu culpa!`,
      description:
        "Este producto no se puede agregar a la bolsa, no tiene precio disponible.",
      placement: notificationPlacement,
    });
  };

  const successAddNotification = (product: Product) => {
    notification.success({
      message: `Producto agregado!`,
      description: `${product.code}, agregado correctamente a tu bolsa.`,
      placement: notificationPlacement,
    });
  };

  const addProductToCart = async (product: Product) => {
    setIsAddingItem(product);
    if (product.publicPrice === null) {
      errorPriceNotification();
      setIsAddingItem(new Product({}));
      return;
    }

    setTimeout(() => {
      setCartList((currentProducts) => {
        if (!currentProducts.find((item) => item.product.id === product.id)) {
          return [...currentProducts, { product, quantity: 1 }];
        } else {
          return currentProducts.map((item) => {
            if (item.product.id === product.id) {
              return { ...item, quantity: item.quantity + 1 };
            } else {
              return item;
            }
          });
        }
      });
      successAddNotification(product);
      setIsAddingItem(new Product({}));
    }, 1200);
  };

  const removeProductFromCart = (product: Product) => {
    setIsAddingItem(product);
    setTimeout(() => {
      setCartList((currentProducts) => {
        if (
          currentProducts.find((item) => item.product.id === product.id)
            ?.quantity == 1
        ) {
          return currentProducts.filter(
            (item) => item.product.id !== product.id
          );
        } else {
          return currentProducts.map((item) => {
            if (item.product.id === product.id) {
              return { ...item, quantity: item.quantity - 1 };
            } else {
              return item;
            }
          });
        }
      });
      setIsAddingItem(new Product({}));
    }, 1200);
  };

  const setCustomProductQuantity = async (
    product: Product,
    quantity: number
  ) => {
    setIsAddingItem(product);
    setTimeout(() => {
      setCartList((currentProducts) => {
        return currentProducts.map((item) => {
          if (item.product.id === product.id) {
            return { ...item, quantity: quantity };
          } else {
            return item;
          }
        });
      });
      setIsAddingItem(new Product({}));
    }, 1200);

    //message.success('La cantidad se actualizo correctamente');
  };

  const getTotal = () => {
    console.log("total");
    let total = 0;
    cartList.forEach((cartItem) => {
      total =
        total +
        (cartItem.product.publicPrice ? cartItem.product.publicPrice : 0) *
          cartItem.quantity;
    });
    return total;
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        addProductToCart,
        removeProductFromCart,
        setCustomProductQuantity,
        getTotal,
        cartList,
        isAddingItem,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}

export default ShoppingCartContext;
