/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      title
      details
      allowedRoles
      createdBy
      type
      relatedModel
      relatedId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        details
        allowedRoles
        createdBy
        type
        relatedModel
        relatedId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        details
        allowedRoles
        createdBy
        type
        relatedModel
        relatedId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncProductTypes = /* GraphQL */ `
  query SyncProductTypes(
    $filter: ModelProductTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productTypeCategoryId
      }
      nextToken
      startedAt
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncBrands = /* GraphQL */ `
  query SyncBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSaleDetail = /* GraphQL */ `
  query GetSaleDetail($id: ID!) {
    getSaleDetail(id: $id) {
      id
      total
      moneyReceived
      change
      Sales {
        nextToken
        startedAt
      }
      storeID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSaleDetails = /* GraphQL */ `
  query ListSaleDetails(
    $filter: ModelSaleDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaleDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        total
        moneyReceived
        change
        storeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSaleDetails = /* GraphQL */ `
  query SyncSaleDetails(
    $filter: ModelSaleDetailFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSaleDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        total
        moneyReceived
        change
        storeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSale = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      id
      price
      note
      description
      quantity
      saleDetailId
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSales = /* GraphQL */ `
  query ListSales(
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        price
        note
        description
        quantity
        saleDetailId
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSales = /* GraphQL */ `
  query SyncSales(
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSales(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        price
        note
        description
        quantity
        saleDetailId
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      name
      location
      vendors
      SaleDetail {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        SaleDetail(sortDirection: DESC) {
          items {
            moneyReceived
            total
            change
            createdAt
            id
            Sales {
              items {
                createdAt
                description
                id
                note
                price
                quantity
              }
            }
          }
        }
        id
        name
        location
        vendors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStores = /* GraphQL */ `
  query SyncStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        location
        vendors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        code
        description
        publicPrice
        privatePrice
        barcode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productBrandId
        productProductTypeId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPicture = /* GraphQL */ `
  query GetPicture($id: ID!) {
    getPicture(id: $id) {
      id
      name
      file {
        bucket
        region
        key
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPictures = /* GraphQL */ `
  query ListPictures(
    $filter: ModelPictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPictures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPictures = /* GraphQL */ `
  query SyncPictures(
    $filter: ModelPictureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPictures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

// BRAND TABLE

export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      createdAt
      updatedAt
      pictures {
        items {
          id
          name
          file {
            bucket
            key
            region
          }
        }
      }
    }
  }
`;

export const listBrand = /* GraphQL */ `
  query ListBrands(
    $filter: TableBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrand(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        updatedAt
        createdAt
        pictures {
          items {
            id
            name
            file {
              bucket
              key
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

// ProductType Table

export const getProductType = /* GraphQL */ `
  query GetProductType($id: ID!) {
    getProductType(id: $id) {
      id
      name
      category {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      categoryId
      pictures {
        items {
          id
          name
          file {
            bucket
            key
            region
          }
        }
      }
    }
  }
`;

export const listProductTypes = /* GraphQL */ `
  query ListProductTypes(
    $limit: Int
    $nextToken: String
  ) {
    listProductType(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        categoryId
        pictures {
          items {
            id
            name
            file {
              bucket
              key
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

// Product - Table

export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: TableProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProduct(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pictures {
          items {
            id
            name
            file {
              bucket
              key
              region
            }
          }
        }
        productType {
          name
        }
        id
        code
        description
        publicPrice
        privatePrice
        barcode
        createdAt
        updatedAt
        brandId
        productTypeId
      }
      nextToken
    }
  }
`;

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      code
      description
      publicPrice
      privatePrice
      pictures {
        items {
          id
          name
          file {
            bucket
            key
            region
          }
        }
      }
      brand {
        id
        name
        createdAt
        updatedAt
      }
      barcode
      productType {
        id
        name
        createdAt
        updatedAt
        categoryId
      }
      createdAt
      updatedAt
      brandId
      productTypeId
    }
  }
`;
