import { ProductCard } from "../ProductCardComponentView/ProductCardComponent";
import { useEffect, useState } from "react";
import { List, Empty, Row, Col } from "antd";
import "./ProductListComponent.css";
import { Product } from "../../models";
import { BarcodeOutlined, LoadingOutlined } from "@ant-design/icons";
import { Scanner } from "../ScannerComponentView/ScannerComponent";
import { Link, useParams } from "react-router-dom";
import useUser from "../_Hooks/useUser";
import { isMobile } from "react-device-detect";
import useProduct from "../_Hooks/useProduct";
import useSearch from "../_Hooks/useSearch";

const spinIcon = <LoadingOutlined style={{ fontSize: 120 }} spin />;

export const ProductList = ({ onlyNewProducts }: any) => {
  const user = useUser();
  const params = useParams();

  const onCompleted = (code: any) => {
    console.log(code.codeResult.code);
  };

  const suffix = (
    <Scanner
      onCompleted={onCompleted}
      CustomItem={(onClick) => (
        <BarcodeOutlined
          onClick={onClick}
          style={{
            fontSize: 16,
            color: "#1890ff",
          }}
        />
      )}
    ></Scanner>
  );

  const EmptyProductListComponent = () => {
    return (
      <Empty
        image={`${window.location.origin}/images/empty-icon.png`}
        imageStyle={{
          marginTop: 50,
          height: 260,
          filter: "grayscale(1)",
        }}
        description={
          <span>
            Por el momento no hay Productos como el que estas buscando...
          </span>
        }
      >
        {(user.isAdmin || user.isStoreManager) && (
          <Link to="/create-product">
            <button className="ButtonPrimaryColor">
              Agregar Nuevo Producto
            </button>
          </Link>
        )}
      </Empty>
    );
  };

  const ApplyFilter = (products) => {
    const { searchText, setSearchText, setIsActive } = useSearch();

    if (!!params?.code) {
      console.log(params?.code)
      setSearchText(params.code);
      setIsActive(true);
    }

    let productList = products.filter((i: any) =>
      params.type && params.type !== "all"
        ? i?.productType?.name.toLowerCase() === params.type
        : true
    );

    if (searchText != "") {
      productList = products.filter(
        (product: any) =>
          product.code
            ?.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          (product.description
            ?.toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) &&
            true)
      );
    }

    productList.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    return productList;
  };

  const ProductList = () => {
    const { products } = useProduct();

    console.log("ProductList ");
    return (
      <>
        <List
          grid={{ gutter: 12, xs: 2, sm: 3, md: 4, lg: 4, xl: 6, xxl: 6 }}
          dataSource={ApplyFilter(products)}
          pagination={{
            size: "small",
            simple: isMobile,
            onChange: (page) => {
              sessionStorage.setItem("currentPage", `${page}`);
              document.getElementById("appContentDiv")?.scroll({
                top: 0,
                behavior: "smooth",
              });
            },
            pageSize: onlyNewProducts ? (isMobile ? 4 : 6) : 24,
            hideOnSinglePage: true,
            showSizeChanger: false,
            responsive: true,
            style: { ...{ textAlign: "center" } },
          }}
          style={{
            margin: 5,
          }}
          renderItem={(product: Product) => (
            <List.Item>
              <ProductCard product={product} showOptions={true} />
            </List.Item>
          )}
        />
      </>
    );
  };

  console.log("ProductListContainer");
  return (
    <div>
      <ProductList></ProductList>
    </div>
  );
};
