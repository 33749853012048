import { MenuOutlined, EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Col, Drawer, Modal, Row } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  CartHeaderTextStyle,
  HeaderIconStyle,
  SubHeaderStyle,
} from "../../enviroment/enviroment";
import "./AppMenuComponent.css";
import useUser from "../_Hooks/useUser";
import { ProductType } from "../../models";
import { ManageProductTypeComponent } from "../ManageProductTypeComponentView/ManageProductTypeComponent";
import useProductType from "../_Hooks/useProductType";

export function AppMenuComponent() {
  const user = useUser();
  const history = useHistory();

  const { productTypes } = useProductType();
  const [visible, setVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [currentProductType, setCurrentProductType] = useState(
    new ProductType({})
  );
  let location = useLocation();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setEditVisible(false);
  };

  const handleCancel = () => {
    setCurrentProductType(new ProductType({}));
    setTimeout(() => {
      setEditVisible(false);
    }, 10);
  };

  const handleClick = (e) => {
    console.log("click ", e);
  };

  return (
    <div>
      <div>
        <div style={{ cursor: "pointer" }} onClick={showDrawer}>
          <MenuOutlined style={HeaderIconStyle} />
        </div>
        <Drawer
          title={
            <div className="DrawerTitle" style={CartHeaderTextStyle}>
              Productos
            </div>
          }
          headerStyle={SubHeaderStyle}
          placement="right"
          closable={true}
          onClose={onClose}
          visible={visible}
          bodyStyle={{
            padding: 0,
          }}
        >
          <div className="ProductTypesContainer">
            <Link
              key={1}
              className={`ProductTypeItem ${
                location.pathname === "/products/all" && "ProductTypeItemActive"
              }`}
              to={`/products/all`}
              onClick={onClose}
            >
              <span>{"Todos los Productos"}</span>
            </Link>
            {productTypes &&
              productTypes.map((productType) => {
                let match =
                  location.pathname ===
                  `/products/${productType.name?.toLocaleLowerCase()}`;
                return (
                  <div key={productType.id}>
                    <Row>
                      <Col
                        className={`ProductTypeItem ${
                          match && "ProductTypeItemActive"
                        }`}
                        span={user.isAdmin ? 20 : 24}
                        onClick={() => {
                          history.push(
                            `/products/${productType.name.toLocaleLowerCase()}`
                          );
                          setVisible(false);
                        }}
                      >
                        <span>{productType.name}</span>
                      </Col>
                      {user.isAdmin && (
                        <Col span={4}>
                          <div
                            onClick={() => {
                              setCurrentProductType(productType);
                              setEditVisible(true);
                            }}
                          >
                            {" "}
                            <EditOutlined key="edit" className="AddIcon" />
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              })}
          </div>
        </Drawer>
        <Modal
          title={`Editar: ${currentProductType.name}`}
          visible={isEditVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          {currentProductType.id && (
            <ManageProductTypeComponent
              productType={currentProductType}
              onUpdated={(updatedProductType) => {
                handleCancel();
              }}
              onDeleted={(deletedProductType) => {
                handleCancel();
              }}
            />
          )}
        </Modal>
      </div>
    </div>
  );
}
