import { List, Row, Col } from "antd";
import "./ProductTypesListComponent.css";
import { Product } from "../../models";
import { isMobile } from "react-device-detect";
import useProductType from "../_Hooks/useProductType";
import { ShowImageComponent } from "../ShowImageComponentView/ShowImageComponent";
import { useHistory } from "react-router-dom";
import { SubHeaderStyle } from "../../enviroment/enviroment";

export const ProductTypesList = ({ onlyNewProducts }: any) => {
  
  const ProductTypeCard = ({ productType }) => {
    const history = useHistory();

    return (
      <div
        onClick={() => {
          history.push(`/products/${productType.name.toLocaleLowerCase()}`);
        }}
      >
        <div className="centered">{productType.name}</div>
        <ShowImageComponent item={productType}></ShowImageComponent>
      </div>
    );
  };

  const ProductTypesList = () => {
    const { productTypes } = useProductType();
    console.log("ProductTypesList ");
    return (
      <>
        <Row className="HomeTitle" style={SubHeaderStyle}>{"TIPOS DE PRODUCTOS"}</Row>
        <List
          grid={{ gutter: 12, xs: 2, sm: 3, md: 4, lg: 4, xl: 6, xxl: 6 }}
          dataSource={productTypes}
          pagination={{
            size: "small",
            simple: isMobile,
            onChange: (page) => {
              sessionStorage.setItem("currentPage", `${page}`);
              document.getElementById("appContentDiv")?.scroll({
                top: 0,
                behavior: "smooth",
              });
            },
            pageSize: onlyNewProducts ? (isMobile ? 4 : 6) : 24,
            hideOnSinglePage: true,
            showSizeChanger: false,
            responsive: true,
            style: { ...{ textAlign: "center" } },
          }}
          style={{
            margin: 5,
          }}
          renderItem={(productType: Product) => (
            <List.Item>
              <ProductTypeCard productType={productType} />
            </List.Item>
          )}
        />
      </>
    );
  };

  console.log("ProductListContainer");
  return (
    <div>
      <ProductTypesList></ProductTypesList>
    </div>
  );
};
