import { API, graphqlOperation } from "aws-amplify";
import { createNotification } from '../../graphql/mutations';

const CryptoJS = require("crypto-js");

export const setSessionStorageItem = (key, item) => {
    let encryptedString = CryptoJS.AES.encrypt(JSON.stringify(item), 'MonceAlien').toString();
    sessionStorage.setItem(key, encryptedString);
}

export const getSessionStorageItem = (key) => {
    const sessionStorageItem = sessionStorage.getItem(key);
    if (sessionStorageItem) {
        let bytes = CryptoJS.AES.decrypt(sessionStorageItem, 'MonceAlien');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return null;
}

export const addNewNotification = async (title, details, allowedRoles, type, relatedModel, relatedId, createdBy) => {
    
    let requestBody = {
        title,
        details,
        allowedRoles,
        createdBy,
        type,
        relatedModel,
        relatedId
    }
    return await API.graphql(graphqlOperation(createNotification, { input: requestBody }));
}
