import { API, graphqlOperation } from "aws-amplify";
import { createContext, ReactNode, useEffect, useState } from "react";
import { listProductTypes } from "../../graphql/queries";
import { ProductType } from "../../models";

type ProductTypeProviderProps = {
  children: ReactNode;
};

type ProductTypeContex = {
  getProductTypeList: () => void;
  setProductTypes: (productTypes: ProductType[]) => void;
  productTypes;
};

const ProductTypeContex = createContext({} as ProductTypeContex);

export function ProductTypeProvider({ children }: ProductTypeProviderProps) {
  
  const initProductTypeState: ProductType[] = [];
  const [productTypes, setProductTypesList] = useState(initProductTypeState);
  const setProductTypes = (productTypes) => setProductTypesList(productTypes);

  useEffect(() => {
    getProductTypeList();
  }, []);

  
  const getProductTypeList = async () => {
    try {
      const productTypeListData: any = await API.graphql(
        graphqlOperation(listProductTypes, {
          limit: 1000,
        })
      );
      let productTypeList: ProductType[] =
        productTypeListData.data.listProductType.items;
      setProductTypesList(productTypeList);
    } catch (error) {
      console.log(error);
      console.log("Error fetching the productTypeList");
    }
  };

  return (
    <ProductTypeContex.Provider
      value={{
        setProductTypes,
        getProductTypeList,
        productTypes
      }}
    >
      {children}
    </ProductTypeContex.Provider>
  );
}

export default ProductTypeContex;
