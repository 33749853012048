import { Col, Row } from "antd";
import { isMobile } from "react-device-detect";
import { ProductList } from "../ProductsListComponentView/ProductListComponent";
import "./HomeComponent.css";
import { ProductTypesList } from "../ProductTypesListComponentView/ProductTypesListComponent";
import { BrandList } from "../BrandListComponentView/BrandListComponent";
import { useEffect, useState } from "react";
import { LoadingComponent } from "../LoadingComponentView/LoadingComponent";
import { SubHeaderStyle } from "../../enviroment/enviroment";

export function HomeComponent() {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsDataLoaded(true);
    }, 1200);
  }, []);

  console.log("Render: HomeContainer");
  return (
    <div className="HomeContainer">
      {
        isDataLoaded ? 
        <Row>
        <Col span={isMobile ? 24 : 24}>
          <Row className="NewProductsContainer">
            <Col className="HomeTitle" style={SubHeaderStyle} span={24}>
              {"NUEVOS"}
            </Col>
            <Col span={24}>
              <ProductList onlyNewProducts={true}></ProductList>
            </Col>
          </Row>

          <Row>
            <Col className="ProductTypeContainer" span={24}>
              <ProductTypesList></ProductTypesList>
            </Col>
            <Col className="ProductTypeContainer" span={24}>
              <BrandList></BrandList>
            </Col>
          </Row>
        </Col>
      </Row>
      :
      <LoadingComponent loadingText={"Cargando, por favor espere..."} ></LoadingComponent>
      }
    </div>
  );
}
