export const AppTitle = "valdemar";
export const WhatsAppNumber = "6621818289";
export const AppLogo = "./favicon.png";

let styles: any = process.env.REACT_APP_STYLES;

const {
  AppBackgroundColor,
  HeaderPrimaryColor,
  HeaderSecondaryColor,
  PrimaryButtonColor,
  SecondaryButtonColor,
  PrimarySubHeaderColor,
  TextSubHeaderColor,
  CardHeaderColor,
  TextCardHeaderColor,
  TextButtonColor,
  LoginTextColor
} = JSON.parse(styles);

export const AppContainerStyle = {
  height: "100vh",
  background: AppBackgroundColor,
  fontWeight: 500,
};

export const HeaderIconStyle = {
  fontSize: 30,
  color: HeaderSecondaryColor,
  marginLeft: 15,
  cursor: "pointer",
};

export const FooterStyle = {
  backgroundColor: HeaderPrimaryColor,
  color: HeaderSecondaryColor
};

export const SubHeaderStyle = {
  backgroundColor: PrimarySubHeaderColor,
  color: TextSubHeaderColor,
};

export const HeaderStyle = {
  backgroundColor: HeaderPrimaryColor,
};

export const HeaderTextStyle = {
  color: HeaderSecondaryColor,
  fontWeight: 700,
};

export const CartHeaderStyle = {
  backgroundColor: PrimarySubHeaderColor,
};

export const CartHeaderTextStyle = {
  color: TextButtonColor,
  fontWeight: 700,
};

export const CardHeaderStyle = {
  color: TextCardHeaderColor,
  backgroundColor: CardHeaderColor,
  fontSize: 16,
  fontWeight: 400,
};

export const CardStyle = {
  width: "auto",
  minHeight: "auto",
  borderBottom: `4px solid ${SecondaryButtonColor}`,
  borderLeft: `4px solid ${SecondaryButtonColor}`,
  borderBottomRightRadius: 12,
  borderTopLeftRadius: 6,
  color: "black",
};

export const PrimaryButtonStyle = {
  borderRadius: 5,
  border: `1px solid ${SecondaryButtonColor}`,
  background: PrimaryButtonColor,
  width: "100%",
};

export const PrimaryButtonTextStyle = {
  padding: 10,
  borderRadius: 5,
  color: TextButtonColor,
};

export const LoadingTextStyle = {
  marginTop: "150px",
  color: LoginTextColor,
  fontSize: 20,
};

export const CurrentSearchTextStyle = {
  color: HeaderSecondaryColor
};
