import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { UserMenuComponent } from "../UserMenuComponentView/UserMenuComponent";
import "./HeaderComponent.css";
import { AppTitle } from "../../enviroment/enviroment";
import { AppMenuComponent } from "../AppMenuComponentView/AppMenuComponent";
import { CartListComponent } from "../CartListComponentView/CartListComponent";
import { SearchComponent } from "../SearchComponentView/SearchComponent";
import useSearch from "../_Hooks/useSearch";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { SearchProvider } from "../_Contexts/SearchContex";
import { HeaderStyle, HeaderTextStyle } from '../../enviroment/enviroment';

function HeaderComponent() {
  const { isSearchActive, setIsActive, setSearchText } = useSearch();
  let history = useHistory();

  return (
    <Navbar className="App-header" style={HeaderStyle}>
      {isMobile ? (
        !isSearchActive && (
          <>
            <Navbar.Brand
              className="appTitleName"
              style={HeaderTextStyle}
              href=""
              onClick={() => {
                history.push("/");
              }}
            >
              {process.env.REACT_APP_DISPLAY_NAME}
            </Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="#home"></Nav.Link>
            </Nav>
          </>
        )
      ) : (
        <>
          <Navbar.Brand
            className="appTitleName"
            href=""
            style={HeaderTextStyle}
            onClick={() => {
              setIsActive(false);
              setSearchText('');
              history.push("/");
            }}
          >
            {process.env.REACT_APP_DISPLAY_NAME}
          </Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link href="#home"></Nav.Link>
          </Nav>
        </>
      )}
      {/* <NotificationComponent /> */}
      <SearchComponent></SearchComponent>
      <CartListComponent></CartListComponent>
      <UserMenuComponent></UserMenuComponent>
      <AppMenuComponent></AppMenuComponent>
    </Navbar>
  );
}

export default () => (
    <HeaderComponent />
);
