import { List, Row } from "antd";
import "./BrandListComponent.css";
import { Brand } from "../../models";
import { isMobile } from "react-device-detect";
import { ShowImageComponent } from "../ShowImageComponentView/ShowImageComponent";
import useBrand from "../_Hooks/useBrand";
import { SubHeaderStyle } from "../../enviroment/enviroment";

export const BrandList = ({ onlyNewProducts }: any) => {

  const BrandCard = ({ brand }) => {
    return (
      <>
        <ShowImageComponent item={brand}></ShowImageComponent>
      </>
    );
  };

  const BrandList = () => {
    const { brands } = useBrand();
    console.log("BrandList ");
    return (
      <>
        <Row className="HomeTitle" style={SubHeaderStyle}>{"MARCAS"}</Row>
        <List
          grid={{ gutter: 12, xs: 2, sm: 3, md: 4, lg: 4, xl: 6, xxl: 6 }}
          dataSource={brands}
          pagination={{
            size: "small",
            simple: isMobile,
            onChange: (page) => {
              sessionStorage.setItem("currentPage", `${page}`);
              document.getElementById("appContentDiv")?.scroll({
                top: 0,
                behavior: "smooth",
              });
            },
            pageSize: onlyNewProducts ? (isMobile ? 4 : 6) : 24,
            hideOnSinglePage: true,
            showSizeChanger: false,
            responsive: true,
            style: { ...{ textAlign: "center" } },
          }}
          style={{
            margin: 5,
          }}
          renderItem={(brand: Brand) => (
            <List.Item>
              <BrandCard brand={brand} />
            </List.Item>
          )}
        />
      </>
    );
  };

  console.log("BrandContainer");
  return (
    <div>
      <BrandList></BrandList>
    </div>
  );
};
