import './SearchComponent.css';
import { Col, Row, Input } from 'antd';
import { SearchOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from "react-router-dom";
import { CurrentSearchTextStyle, HeaderIconStyle } from '../../enviroment/enviroment';
import useSearch from '../_Hooks/useSearch';
import { useState } from 'react';

export function SearchComponent() {

    const { isSearchActive, searchText, setIsActive, setSearchText } = useSearch();
    const [currentText, setCurrentText] = useState('');
    let history = useHistory();
    let location = useLocation();

    return (
        <>
            <Row>
                <Col span={20}>
                    {!isSearchActive ?
                        <SearchOutlined onClick={() => {
                            setCurrentText('');
                            setIsActive(true);
                        }} style={HeaderIconStyle} />
                        :
                        <>
                            <Input size="large" value={currentText} placeholder="Busca algo..." prefix={<SearchOutlined />} allowClear
                                onChange={({ target }: any) => {
                                    const { value } = target;
                                    setCurrentText(value.replace('/', ''));
                                    if (value === '' && location.pathname.includes('/products/all')) {
                                        history.push(`/products/all`);
                                    }
                                }}
                                onPressEnter={() => {
                                    currentText !== '' && history.push(`/products/all/${currentText.toLocaleLowerCase()}`)
                                    setSearchText(currentText);
                                }}
                            />
                            {searchText && <div style={CurrentSearchTextStyle}>Busqueda actual: "{searchText}"</div>}
                        </>
                    }
                </Col>
                <Col style={{ marginTop: '2%' }} span={4}>
                    {
                        isSearchActive &&
                        <CloseOutlined onClick={() => {
                            setIsActive(false);
                            setCurrentText('');
                            setSearchText('');

                            if (location.pathname.includes('/products/all/')) {
                                history.push(`/products/all`);
                            }
                        }} style={HeaderIconStyle} />
                    }
                </Col>
            </Row>
        </>
    );
}
